<template>

  <div class="map-container">

    <div id="loading-bg" v-if="loading===true" style="z-index: 1; background-color: white">
      <div class="loading-logo">
        <img src="https://dev-caleb.opstunnel.com/logo.png" alt="Logo" />
      </div>
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div>
    <MglMap
      :accessToken="accessToken"
      :mapStyle="mapStyle"
      :logoPosition="logoPosition"
      :mapbox-gl="mapboxgl"
      container="map-container"
      :center="center"
      :zoom="zoom"
      @load="onMapLoaded"
    >
        <portal-target name="mapSidebar"></portal-target>
      <DrawControl
        v-if="drawControl === true"
        :displayControlsDefault="displayControls"
        :controls="controls"
        @create="handleCreate"
        @update="handleUpdate"
        @delete="handleDelete"
      />
      <div v-if="geoControl === true && !loading">
        <MglMarker
      v-for="region in regions"
      :key="region.name"
      :coordinates="get_polygon_centroid(region.geoJSON[0].features[0].geometry.coordinates[0])"
        >
        <div slot="marker">
          <h1 class="region-label">{{region.name}}</h1>
        </div>
      </MglMarker>
      </div>
      <div v-if="markerControl === true">
        <MglMarker
          v-for="site in sites"
          :key="site.name"
          @click="flyToStore(site.geoJSON[0].features[0])"
          :coordinates="site.geoJSON[0].features[0].geometry[0].coordinates"
          :draggable="draggable"
          @dragend="handleMarkerUpdate"
          :id="'marker-'+site.siteID"
          :ref="'marker-'+site.siteID"
        >
        <div slot="marker">
          <div class='pin' :id="'pin-'+site.siteID" @mouseover="handleMarkerAnimationShow(site.siteID)" @mouseout="handleMarkerAnimationHide(site.siteID)"></div>
          <div :id="'pin-base-animated-'+site.siteID" class='pulse hidden'></div>
          <div :id="'pin-base-'+site.siteID" class='base-pulse'></div>
        </div>
          <MglPopup 
          v-if="selectable"
          focusAfterOpen: true
          @open="popupOpened(site.siteID)"
          @close="popupClosed"
           :className="'popup-'+site.siteID">
            <div>
              <h3>{{ site.name }}</h3>
              <h4>{{ site.lsd }}</h4>
              <div class="text-center" v-if="(site.cameras.length + site.rdps.length + site.quicklinks.length)>0">
                <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                @click="handleShowSiteModal(site)"
              >
                More Info
              </b-button>
              </div>
            </div>
          </MglPopup>
        </MglMarker>
      </div>
      <MglGeojsonLayer
        v-if="geoControl === true"
        :source-id="'geojson-source'"
        :source="regionSource()"
        :layer-id="'geojson-layer'"
        :layer="getGeoJsonLayer()"
        :clear-source="true"
        :replace="true"
      />
    </MglMap>
  </div>
</template>

<script>
// import mapbox
import { MglMap, MglMarker, MglGeojsonLayer, MglPopup } from 'v-mapbox'
import mapboxgl from 'mapbox-gl'
import MapboxDraw from '@mapbox/mapbox-gl-draw'

// Import config for mapbox api keys
import config from '@/config'

// import mapbox css
import 'mapbox-gl/dist/mapbox-gl.css'
import 'v-mapbox/dist/v-mapbox.css'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

// import bootstrap components
import { BFormInput, BButton } from 'bootstrap-vue'
import DrawControl from './Draw.vue'
import Ripple from 'vue-ripple-directive'
import ContractorTable from '../siteEditor/ContractorTable.vue'

export default {
  name: 'Map',
  components: {
    MglMap,
    MglMarker,
    BFormInput,
    BButton,
    MapboxDraw,
    MglGeojsonLayer,
    mapboxgl,
    MglPopup,
    DrawControl,
    ContractorTable
  },
  data() {
    return {
      accessToken: config.env.MAP_ACCESS_KEY,
      mapStyle: config.env.MAP_THEME_PATH,
      center: [-114.93571356110009, 55.75009437940531],
      zoom: 3.3,
      logoPosition: 'top-right',
      mapboxgl,
      markerOptions: {
        draggable: true,
        coordinates: [-110.93571356110009, 55.75009437940531]
      },
      loading: true,
      selectedMarkerID: '',
    }
  },
  created() {
    this.mapboxgl = null
    this.loading = true
    window.Bus.instance.$on('flyToCoords', (c) =>{
      this.mapbox.flyTo({center:c, zoom: 3.3})
    })
    window.Bus.instance.$on('flyToFeature', (a) => {
      
      if (a != undefined) {
        this.SelectMarker(a)
      }
    })
  },
  props: {
    sidebarControl: {
      type: Boolean,
      default: false
    },
    selectable:{
      type: Boolean,
      default: true
    },
    drawControl: {
      type: Boolean,
      default: false
    },
    markerControl: {
      type: Boolean,
      default: false
    },
    geoControl: {
      type: Boolean,
      default: false
    },
    displayControls: {
      type: Boolean,
      default: false
    },
    geojsonlayer: {
      type: Boolean,
      default: false
    },
    controls: {
      default: () => ({
        polygon: true,
        trash: true
      })
    },
    draggable: {
      type: Boolean,
      default: true
    },
    sites: {
      default: () => []
    },
    regions: {
      default: () => [
        {
          type: 'FeatureCollection',
          features: [{ type: 'Feature', geometry: [{ coordinates: [10, 10] }] }]
        }
      ]
    }
  },
  mounted() {},
  directives: {
    Ripple
  },
  methods: {
    popupOpened(siteID) {
      this.selectedMarkerID = siteID
    },
    popupClosed() {
      this.selectedMarkerID = ''
    },
    SelectMarker(site) {
      if (this.selectedMarkerID != '') {
        this.$refs['marker-' + this.selectedMarkerID][0].togglePopup()
      }
      
      // add .5 to site.geoJSON[0].features[0].geometry[0].coordinates[1]
      const flyCoordinates = []
      if (this.$store.getters['app/currentBreakPoint'] == 'xs') {
        flyCoordinates.push(site.geoJSON[0].features[0].geometry[0].coordinates[0])
        flyCoordinates.push(site.geoJSON[0].features[0].geometry[0].coordinates[1] - 0.00175)
      } else {
        flyCoordinates.push(site.geoJSON[0].features[0].geometry[0].coordinates[0])
        flyCoordinates.push(site.geoJSON[0].features[0].geometry[0].coordinates[1])
      }
      this.mapbox.flyTo({
        center: flyCoordinates,
        zoom: 15
      })
      let temp = this.$refs['marker-' + site.siteID]
      if (temp != undefined && temp.length > 0) {
        temp[0].togglePopup()
      }
    },
    closeAllPopups() {
      // THIS WILL REMOVE NOT TOGGLE
      const popup = document.getElementsByClassName('mapboxgl-popup')
      if (popup.length) {
        popup[0].remove()
      }
    },
    onMapLoaded(event) {
      // in component
      this.mapbox = event.map
      window.dispatchEvent(new Event('resize'))
      this.computeMapCenter()
      this.loading = false
    },
    flyToStore(currentFeature) {
      this.mapbox.flyTo({
        center: currentFeature.geometry[0].coordinates,
        zoom: 15
      })
    },
    computeMapCenter() {
      this.center = this.get_polygon_centroid(this.getAllCoordsForCentroid())
    },
    getAllCoordsForCentroid() {
      let coords = []
      for (let i = 0; i < this.sites.length; i++) {
        coords.push(
          this.sites[i].geoJSON[0].features[0].geometry[0].coordinates
        )
      }
      if (this.geoControl) {
        for (let i = 0; i < this.regions.length; i++) {
          for (
            let j = 0; j < this.regions[i].geoJSON[0].features[0].geometry.coordinates[0].length;j++) {
            coords.push(
              this.regions[i].geoJSON[0].features[0].geometry.coordinates[0][j]
            )
          }
        }
      }
      if (coords.length === 0) {
        coords.push([-114.93571356110009, 55.75009437940531])
      }
      return coords
    },
    // pts is an array of array of ints. eg: [[1,1],[2,2]]
    get_polygon_centroid(pts) {
      if(pts === undefined){
        return [-114.93571356110009, 55.75009437940531]
      } else
      if(pts.length === 1){
        return pts[0]
      }
      var first = pts[0],
        last = pts[pts.length - 1]
      if (first[1] != last[1] || first[0] != last[0]) {
        pts.push(first)
      }
      var twicearea = 0,
        x = 0,
        y = 0,
        nPts = pts.length,
        p1,
        p2,
        f
      for (var i = 0, j = nPts - 1; i < nPts; j = i++) {
        p1 = pts[i]
        p2 = pts[j]
        f =
          (p1[0] - first[0]) * (p2[1] - first[1]) -
          (p2[0] - first[0]) * (p1[1] - first[1])
        twicearea += f
        x += (p1[1] + p2[1] - 2 * first[1]) * f
        y += (p1[0] + p2[0] - 2 * first[0]) * f
      }
      f = twicearea * 3
      return [y / f + first[0], x / f + first[1]]
    },
    /////////////////////////////////////////////////// Marker Controls ////////////////////////////////

    handleMarkerAnimationShow(siteID) {
      document
        .getElementById('pin-base-animated-' + siteID)
        .classList.remove('hidden')
      document.getElementById('pin-' + siteID).classList.add('pin-hover')
      if (this.sidebarControl) {
        document
          .getElementById('siteListItem-' + siteID)
          .classList.add('hovered')
      }
    },
    handleMarkerAnimationHide(siteID) {
      document
        .getElementById('pin-base-animated-' + siteID)
        .classList.add('hidden')
      document.getElementById('pin-' + siteID).classList.remove('pin-hover')
      if (this.sidebarControl) {
        document
          .getElementById('siteListItem-' + siteID)
          .classList.remove('hovered')
      }
    },

    handleShowSiteModal(site) {
      // Optionally the popup can be closed programatically like so
      //document.getElementsByClassName('popup-'+site.siteID)[0].remove()
      this.$emit('showSiteModal', site)
    },

    handleMarkerUpdate(event) {
      console.log('handleMarkerUpdate', event)
      let markerLocation = event.marker._lngLat
      this.$emit('handlemarkerupdate', markerLocation)
    },
    regionSource() {
      let features = []
      this.regions.forEach((region) => {
        if (region.geoJSON) {
          features.push({
            type: 'Feature',
            id: region.geoJSON[0].features[0].id,
            geometry: {
              type: 'Polygon',
              coordinates: region.geoJSON[0].features[0].geometry.coordinates
            }
          })
        }
      })
      let regionCollection = {
        type: 'FeatureCollection',
        features: features
      }
      return {
        type: 'geojson',
        data: regionCollection
      }
    },
    handleCreate(event) {
      let createLocation = event.features[0]
      this.$emit('addpolygonlocation', createLocation)
    },
    handleUpdate(event) {
      let updateLocation = event.features[0]
      this.$emit('updatepolygonlocation', updateLocation)
    },
    handleDelete: function (event) {
      let deleteLocation = event.features[0]
      this.$emit('deletepolygonlocation', deleteLocation)
    },
    getGeoJsonSource(region) {
      let regionSelect = region.geoJSON[0].features[0]
      return {
        type: 'geojson',
        data: regionSelect
      }
    },
    getSiteSource() {
      this.sites.forEach((site) => {
        site[0].geoJSON[0].features[0].geeometry[0].coordintes
      })
    },
    getGeoJsonLayer() {
      return {
        id: 'geojson-layer',
        type: 'fill',
        source: 'geojson-source',
        paint: {
          'fill-color': '#181f66',
          'fill-antialias': true,
          'fill-opacity': ['interpolate', ['linear'], ['zoom'], 1, 1, 15, 0]
        }
      }
    }
  }
}
</script>
<style lang="scss">
.region-label {
  color: #c7c7c7;
}
.hovered {
  background-color: #ffdddd8e;
}
.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: white;
  position: absolute;
  transform: translateY(-15px) translateX(5px) rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}
.pin:after {
  content: '';
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: red;
  position: absolute;
  border-radius: 50%;
}
.pin-hover {
  transform: translateY(-18px) translateX(5px) rotate(-45deg) scale(1);
}

.base-pulse {
  background: #d6d4d4;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: translateY(-15px) translateX(5px) rotateX(55deg);
  z-index: -2;
}

.pulse {
  background: #d6d4d4;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: translateY(-15px) translateX(5px) rotateX(55deg);
  z-index: -2;
}
.pulse:after {
  content: '';
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px red;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}

// @-moz-keyframes pulse {
// 	0% {
// 		transform: scale(0.95);
// 		box-shadow: 0 0 0 0 rgba(219, 219, 219, 0.7);
// 	}

// 	70% {
// 		transform: scale(1.5);
// 		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
// 	}

// 	100% {
// 		transform: scale(0.95);
// 		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
// 	}
// }

// @-o-keyframes pulse {
// 	0% {
// 		transform: scale(0.95);
// 		box-shadow: 0 0 0 0 rgba(219, 219, 219, 0.7);
// 	}

// 	70% {
// 		transform: scale(1.5);
// 		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
// 	}

// 	100% {
// 		transform: scale(0.95);
// 		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
// 	}
// }

// @-webkit-keyframes pulse {
// 	0% {
// 		transform: scale(0.95);
// 		box-shadow: 0 0 0 0 rgba(219, 219, 219, 0.7);
// 	}

// 	70% {
// 		transform: scale(1.5);
// 		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
// 	}

// 	100% {
// 		transform: scale(0.95);
// 		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
// 	}
// }

.container-default {
  max-height: 80vh;
  width: 100%;
}

.map-container {

  text-align: justify;
}
.mapboxgl-popup-content {
  -webkit-text-size-adjust: 100%;
  text-align: left;
  color: #404040;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  position: relative;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  pointer-events: auto;
  font: 400 15px/22px 'Source Sans Pro', 'Helvetica Neue', Sans-serif;
  padding: 0;
  width: 280px;
}

.mapboxgl-popup-content h3 {
  -webkit-text-size-adjust: 100%;
  text-align: left;
  pointer-events: auto;
  font: 400 15px/22px 'Source Sans Pro', 'Helvetica Neue', Sans-serif;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 300;
  font-size: 1.5625rem;
  line-height: 1.4em;
  background: #171f69;
  color: #fff;
  margin: 0;
  display: block;
  padding: 10px;
  border-radius: 3px 3px 0 0;
  margin-top: -15px;
}

.mapboxgl-popup-content h4 {
  -webkit-text-size-adjust: 100%;
  text-align: left;
  pointer-events: auto;
  font: 400 15px/22px 'Source Sans Pro', 'Helvetica Neue', Sans-serif;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  color: inherit;
  -webkit-font-smoothing: antialiased;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 1.125rem;
  line-height: 1.4em;
  font-weight: 300;
  margin: 0;
  display: block;
  padding: 10px 10px 10px 10px;
}

.mapboxgl-popup-content .text-center {
  -webkit-text-size-adjust: 100%;
  color: #404040;
  pointer-events: auto;
  font: 400 15px/22px 'Source Sans Pro', 'Helvetica Neue', Sans-serif;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  text-align: center !important;
  padding: 10px;
}

.mapboxgl-popup-content .text-center .btn {
  -webkit-text-size-adjust: 100%;
  pointer-events: auto;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  font-family: inherit;
  overflow: visible;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  position: relative;
  padding: 12px 30px;
  margin: 0.3125rem 1px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.428571;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0;
  border: 0;
  border-radius: 0.2rem;
  outline: 0;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow, transform;
  -webkit-appearance: button;
  color: #fff;
  background-color: #171f69 !important;
  border-color: #171f69 !important;
  box-shadow: 0 2px 2px 0 rgba(0, 188, 212, 0.14),
    0 3px 1px -2px rgba(0, 188, 212, 0.2), 0 1px 5px 0 rgba(0, 188, 212, 0.12);
  cursor: pointer;
}

.mapboxgl-popup-content .mapboxgl-popup-close-button {
  -webkit-text-size-adjust: 100%;
  pointer-events: auto;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  overflow: visible;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  border-radius: 0 3px 0 0;
  cursor: pointer;
  background-color: transparent;
  -webkit-appearance: button;
}
</style>
