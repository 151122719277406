<template>
  <div class="row">
    <!-- Rdp Table -->
    <div class="col-12">
      <div class="vue-form-wizard mb-3 md">
        <div class="d-flex justify-content-between flex-wrap mr-1 ml-1">
          <div class="d-flex align-items-center mb-1 mt-1">
            <feather-icon icon="ServerIcon" size="25" class="ml-1 mr-1" />
            <h3 class="">Remote Desktops</h3>
            <span class="text-nowrap ml-1">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-toggle.collapse-1
                variant="primary"
                @click="showCreationWizard()"
              >
                <feather-icon icon="PlusCircleIcon" size="16" class="mr-1" />
                Add Desktop
              </b-button>
            </span>
          </div>
        </div>

        <!-- creation Wizard -->
        <b-collapse id="rdp-creator" class="ml-1 mr-1">
          <b-card id="rdp-creator">
            <validation-observer ref="rdpCreate" tag="form">
              <b-row ref="row" class="ml-1">
                <b-col md="3">
                  <b-form-group label="Name" label-for="rdp-name">
                    <validation-provider
                      rules="noSpecial|required"
                      name="rdp name"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        type="text"
                        placeholder="RDP Name"
                        v-model="newRDP.name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="IP" label-for="rdp-ip">
                    <validation-provider
                      rules="required"
                      name="rdp ip"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        id="rdp-ip"
                        v-model="newRDP.ip"
                        type="text"
                        placeholder="IP Address"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="Type" label-for="rdp-protocol">
                    <validation-provider
                      rules="required"
                      name="rdp protocol"
                      v-slot="{ errors }"
                    >
                      <v-select
                        placeholder="Select Type"
                        id="remote-protocol"
                        :reduce="(option) => option.protocol"
                        :options="createConnection"
                        :state="errors.length > 0 ? false : null"
                        v-model="newRDP.protocol"
                        @option:selected="changedProtocol(newRDP.protocol)"
                        class="border rounded"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="Additional Attributes"
                    label-for="rdp-ip"
                  >
                    <validation-provider rules="required" name="rdp protocol">
                      <v-select
                        v-model="newConnection"
                        multiple
                        :placeholder="chooseAttribute.name"
                        label="group"
                        :close-on-select="false"
                        :disabled="!newRDP.protocol"
                        :deselectFromDropdown="true"
                        :clearable="true"
                        class="border rounded"
                        :options="rdpParameterList(newRDP.protocol)"
                      >
                      </v-select>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <div v-for="(parameter, index) in newConnection" :key="index">
                <hr />
                <b-row class="ml-1 mr-1">
                  <b-col md="12">
                    <h3>{{ parameter.group }}</h3>
                  </b-col>
                  <b-col
                    md="3"
                    v-for="(attribute, index) in parameter.parameters"
                    :key="index"
                    class="mb-1"
                  >
                    <b-form-group :label="attribute.description">
                      <validation-provider
                        :rules="attribute.validation"
                        :name="attribute.description"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          :type="attribute.type"
                          v-if="
                            attribute.type === 'text' ||
                            attribute.type === 'password' ||
                            attribute.type === 'number'
                          "
                          :placeholder="attribute.default"
                          v-model="attribute.value"
                        />

                        <b-form-checkbox
                          v-if="attribute.type === 'boolean'"
                          v-model="attribute.value"
                          :placeholder="attribute.default"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <b-col md="2">
                <span class="text-nowrap ml-1">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    @click.prevent="validateRDPCreator()"
                    class="mt-1"
                  >
                    Submit
                  </b-button>
                </span>
              </b-col>
            </validation-observer>
          </b-card>
        </b-collapse>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          styleClass="vgt-table"
          :pagination-options="{
            enabled: true,
            perPage: pageLength
          }"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Action -->
            <span v-if="props.column.field === 'actions'">
              <feather-icon
                icon="TrashIcon"
                size="16"
                class="text-body align-middle mr-1 trash-icon"
                @click="showDeleteModal(props.row)"
              />
              <feather-icon
                icon="EditIcon"
                size="16"
                class="text-body align-middle mr-25 table-icon"
                @click="showEditorInSidebar(props.row)"
              />
            </span>

            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- Pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap mb-1 ml-1"> Show</span>

                <b-form-select
                  v-model="pageLength"
                  :options="['5', '10', '20', '50', '100']"
                  class="mx-1 mb-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-1 mr-1"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </div>

    <!-- Editor Sidebar -->
    <b-sidebar
      id="sidebar-rdp-editor"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @hidden="resetEditor"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Update {{ editRDPData.name }}</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Body -->
        <b-form class="m-2">
          <validation-observer ref="simpleRules">
            <b-row>
              <b-col>
                <b-form-group label="Connection Name" label-for="rdp-name">
                  <validation-provider
                    rules="required"
                    name="rdp name"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="editRDPData.name"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Connection IP" label-for="rdp-ip">
                  <validation-provider
                    rules="required"
                    name="rdp ip"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="editRDPData.ip"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <h4>Additional Parameters</h4>
                <hr />
                <div
                  v-for="(editparameter, index) in editRDPData.parameters"
                  :key="index"
                >
                  <p>{{ editparameter.group }}</p>
                  <b-col
                    v-for="(editattribute, index) in editparameter.parameters"
                    :key="index"
                    class="mb-1"
                  >
                    <b-form-group :label="editattribute.description">
                      <validation-provider
                        :rules="editattribute.validation"
                        :name="editattribute.description"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          :type="editattribute.type"
                          v-if="
                            editattribute.type === 'text' ||
                            editattribute.type === 'password' ||
                            editattribute.type === 'number'
                          "
                          :placeholder="editattribute.default"
                          v-model="editattribute.value"
                        />

                        <b-form-checkbox
                          v-if="editattribute.type === 'boolean'"
                          v-model="editattribute.value"
                          :placeholder="editattribute.default"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <!-- submit -->
              <b-col md="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  @click.prevent="validateRDPEditor()"
                  variant="primary"
                  class="mr-1"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-form>
      </template>
    </b-sidebar>

    <!-- Deletion Modal -->
    <b-modal
      id="delete-rdp-modal"
      hide-footer
      centered
      @hidden="clearDeleteModal()"
    >
      <template #modal-title> Delete Connection</template>
      <div class="d-block text-center">
        <h3>Are you sure you want to delete: {{ rdpToDelete.name }}?</h3>
      </div>
      <div class="mt-2" align="right">
        <b-button variant="primary" @click="$bvModal.hide('delete-rdp-modal')"
          >Cancel</b-button
        >
        <b-button class="ml-1" variant="outline-danger" @click="DeleteRDP()"
          >Delete</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BPagination,
  BFormInput,
  BFormSelect,
  BDropdown,
  BModal,
  BSidebar,
  BCard,
  BCardBody,
  BCardTitle,
  BDropdownItem,
  BCollapse,
  BBadge,
  BFormGroup,
  BButton,
  VBToggle,
  BForm,
  BRow,
  BCol,
  BDropdownGroup,
  BFormCheckbox,
  BFormInvalidFeedback
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

// import Mixins
import { heightTransition } from '@core/mixins/ui/transition'
import prepareQueryParamsMixin from '@/mixins/prepareQueryParamsMixin'
import prepareFetchParamsMixin from '@/mixins/prepareFetchParamsMixin'
import vSelect from 'vue-select'

// import validation for editors
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

// import Services
import { regionGroup } from '@/services/regionGroup.service.js'
import { site } from '@/services/site.service.js'

// Import Table
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

// Import Icons
import FeatherIcon from '../../@core/components/feather-icon/FeatherIcon.vue'

// Declare component
export default {
  name: 'RDPTable',
  prop: ['site'],
  mixins: [prepareQueryParamsMixin, prepareFetchParamsMixin],
  components: {
    ValidationProvider,
    ValidationObserver,
    BCollapse,
    BButton,
    VBToggle,
    BModal,
    BBadge,
    BPagination,
    BForm,
    BFormInput,
    BFormSelect,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
    BSidebar,
    BCard,
    BCardBody,
    BCardTitle,
    BRow,
    BCol,
    BDropdownGroup,
    BFormCheckbox,
    FeatherIcon,
    BFormInvalidFeedback,
    vSelect
  },
  directives: {
    FeatherIcon,
    'b-toggle': VBToggle,
    Ripple
  },
  mixins: [heightTransition],
  data() {
    return {
      rdpToDelete: {
        name: '',
        ip: '',
        protocol: '',
        parameters: {},
        _id: ''
      },
      newRDP: {
        name: '',
        ip: '',
        protocol: '',
        parameters: []
      },

      chooseAttribute: {
        name: '(Optional) Choose Connection Type First'
      },
      siteData: {
        _id: '',
        name: '',
        siteID: '',
        lsd: '',
        lat: '',
        long: '',
        regionGroupID: '',
        groupName: '',
        cameras: [
          {
            name: '',
            url: '',
            img: '',
            _id: ''
          }
        ],
        rdps: [
          {
            name: '',
            ip: '',
            protocol: '',
            parameters: [],
            _id: ''
          }
        ],
        quicklinks: [
          {
            name: '',
            url: '',
            id: '',
            class: ''
          }
        ]
      },

      createConnection: [
        { label: 'RDP', protocol: 'rdp' },
        { label: 'VNC', protocol: 'vnc' }
      ],
      editConnectionType: [
        { label: 'RDP', protocol: 'rdp' },
        { label: 'VNC', protocol: 'vnc' }
      ],
      newConnection: [],
      editConnection: [],
      pageLength: 5,
      rows: [],
      columns: [
        {
          label: 'Name',
          field: 'name',
          width: '20%'
        },
        {
          label: 'Connection IP',
          field: 'ip',
          width: '20%'
        },
        {
          label: 'Connection Type',
          field: 'protocol',
          width: '40%'
        },
        {
          label: 'Actions',
          field: 'actions',
          width: '10%'
        }
      ],
      editRDPData: {
        name: '',
        img: '',
        ip: '',
        parameters: [],
        _id: ''
      }
    }
  },
  methods: {
    rdpProtocols() {
      return [
        { label: 'RDP', protocol: 'rdp' },
        { label: 'VNC', protocol: 'vnc' }
      ]
    },
    ////////////////////////////////////////////////////// RDP Deletion Controls ///////////////////////////////////////////
    DeleteRDP() {
      let updatedSite = JSON.parse(JSON.stringify(this.siteData))
      updatedSite.rdps.splice(
        this.rdpToDelete.originalIndex,
        this.rdpToDelete.originalIndex + 1
      )
      site
        .updateSite(updatedSite)
        .then((res) => {
          // set site data to edited
          window.Bus.instance.$emit('pageSuccess', {
            header: 'Success',
            detail: 'Remote Connection Successfully Deleted'
          })
          this.siteData.rdps.splice(
            this.rdpToDelete.originalIndex,
            this.rdpToDelete.originalIndex + 1
          )
          this.$bvModal.hide('delete-rdp-modal')
        })
        .catch((error) => {
          window.Bus.instance.$emit('pageDanger', {
            header: 'Error',
            detail: 'Could not Delete RDP'
          })
        })
    },
    showDeleteModal(rdp) {
      this.rdpToDelete = rdp
      this.$bvModal.show('delete-rdp-modal')
    },
    clearDeleteModal() {
      this.rdpToDelete = {
        name: '',
        ip: '',
        protocol: '',
        parameters: []
      }
    },
    ////////////////////////////////////////////////////// RDP Creation Controls ///////////////////////////////////////////
    showCreationWizard() {
      this.$root.$emit('bv::toggle::collapse', 'rdp-creator')
    },

    validateRDPCreator() {
      this.$refs.rdpCreate.validate().then((success) => {
        if (success) {
          let updatedSite = JSON.parse(JSON.stringify(this.siteData))

          const connectionParameters = this.newConnection.flatMap((param) => {
            const createNewParameters = []
            const flattenedParameters = param.parameters.flatMap((mapped) => {
              if (mapped.value !== '') {
                createNewParameters.push({
                  name: mapped.name,
                  value: mapped.value,
                  group: param.group
                })
              }
            })
            // if there are no parameters, remove the group
            return createNewParameters
          })
          this.newRDP.parameters = connectionParameters
          updatedSite.rdps.push(this.newRDP)

          site
            .updateSite(updatedSite)
            .then((res) => {
              // set site data to edited
              this.siteData.rdps.push(this.newRDP)

              this.$root.$emit('bv::toggle::collapse', 'rdp-creator')
              this.$refs.rdpCreate.reset()
              this.resetCreator()
              window.Bus.instance.$emit('pageSuccess', {
                header: 'Success',
                detail: 'Remote Connection Successfully Created'
              })
            })
            .catch((error) => {
              window.Bus.instance.$emit('pageDanger', {
                header: 'Error',
                detail: error.message
              })
            })
        }
      })
    },

    resetCreator() {
      this.newRDP = {
        name: '',
        ip: '',
        protocol: '',
        parameters: []
      }
      this.newConnection = []
      this.rdpParameterList('reset')
    },

    ////////////////////////////////////////////////////// RDP Editor Controls //////////////////////////////////////////////

    resetEditor() {
      // clear out editor object
      this.editRDPData = {
        name: '',
        ip: '',
        protocol: '',
        parameters: [],
        _id: ''
      }
      this.editConnection = []
    },
    validateRDPEditor() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          const connectionParameters = this.editRDPData.parameters.flatMap(
            (param) => {
              // flatmap every param.parameters
              const flattenedParameters = param.parameters.flatMap((mapped) => {
                return {
                  name: mapped.name,
                  value: mapped.value,
                  group: param.group
                }
              })
              return flattenedParameters
            }
          )

          this.siteData.rdps[this.editRDPData.originalIndex].name =
            this.editRDPData.name
          this.siteData.rdps[this.editRDPData.originalIndex].ip =
            this.editRDPData.ip
          this.siteData.rdps[this.editRDPData.originalIndex].protocol =
            this.editRDPData.protocol
          this.siteData.rdps[this.editRDPData.originalIndex].parameters =
            connectionParameters
          site
            .updateSite(this.siteData)
            .then((res) => {
              // set site data to edited
              window.Bus.instance.$emit('pageSuccess', {
                header: 'Success',
                detail: 'Remote Connection Successfully Updated'
              })
              this.$root.$emit('bv::toggle::collapse', 'sidebar-rdp-editor')
            })
            .catch((error) => {
              window.Bus.instance.$emit('pageDanger', {
                header: 'Error',
                detail: error.message
              })
            })
        }
      })
    },
    showEditorInSidebar(rdp) {
      const parameterList = this.rdpParameterList(rdp.protocol)
      const editSite = rdp
      const newEdit = []
      if (!editSite.matched) {
        const matchGroups = parameterList.map((groups) => {
          const matchGroup = editSite.parameters.filter((param) => {
            if (param.group === groups.group) {
              const matchParams = groups.parameters.filter((parameter) => {
                if (parameter.name === param.name) {
                  newEdit.push({
                    group: groups.group,
                    parameters: {
                      name: parameter.name,
                      type: parameter.type,
                      validation: parameter.validation,
                      required: parameter.required,
                      description: parameter.description,
                      value: param.value
                    }
                  })
                  return matchParams
                }
              })
            }
          })
        })

        // merge newEdit objects that all have the same group, but different parameters
        const flattenedPram = newEdit.reduce((acc, curr) => {
          const group = acc.find((g) => g.group === curr.group)
          if (group) {
            group.parameters.push(curr.parameters)
          } else {
            acc.push({
              group: curr.group,
              parameters: [curr.parameters]
            })
          }
          return acc
        }, [])

        editSite.parameters = flattenedPram
        editSite.matched = true
      }
      this.editRDPData = editSite
      this.$root.$emit('bv::toggle::collapse', 'sidebar-rdp-editor')
    },
    changedProtocol(protocol) {
      if (protocol === 'rdp') {
        this.newConnection = [
          {
            group: 'Port',
            parameters: [
              {
                name: 'port',
                type: 'number',
                validation: 'between:1,65535',
                required: false,
                description: 'Port of the server',
                default: '3389',
                value: '3389'
              },
              {
                name: 'security',
                type: 'text',
                validation: 'required',
                required: false,
                description: 'Security of the server',
                default: '',
                value: 'any'
              },
              {
                name: 'ignore-cert',
                type: 'boolean',
                validation: 'required',
                required: false,
                description: 'Ignore certificate',
                default: '',
                value: true
              }
            ]
          }
        ]
      } else {
        this.newConnection = [
          {
            group: 'Port',
            parameters: [
              {
                name: 'port',
                type: 'number',
                validation: 'between:1,65535',
                required: false,
                description: 'Port of the server',
                default: '5900',
                value: '5900'
              }
            ]
          }
        ]
      }
    },
    rdpParameterList(type) {
      if (type === 'rdp') {
        const rdpParameters = [
          {
            group: 'Port',
            parameters: [
              {
                name: 'port',
                type: 'number',
                validation: 'between:1,65535',
                required: false,
                description: 'Port of the server',
                default: '8080',
                value: '3389'
              }
            ]
          },
          {
            group: 'Authentication',
            parameters: [
              {
                name: 'username',
                type: 'text',
                validation: 'max:50',
                required: false,
                description: 'Username for the connection',
                default: 'admin',
                value: ''
              },
              {
                name: 'password',
                type: 'password',
                validation: 'max:60',
                required: false,
                description: 'Password for the connection',
                default: 'admin',
                value: ''
              }
            ]
          },
          {
            group: 'Display',
            parameters: [
              {
                name: 'read-only',
                type: 'boolean',
                required: false,
                description: 'Read only mode',
                default: false,
                value: ''
              },
              {
                name: 'swap-red-blue',
                type: 'boolean',
                required: false,
                description: 'Swap red and blue',
                default: false,
                value: ''
              },
              {
                name: 'cursor',
                type: 'boolean',
                required: false,
                description: 'Show mouse cursor',
                default: true,
                value: ''
              },
              {
                name: 'color-depth',
                type: 'number',
                validation: 'between:1,65535',
                required: false,
                description: 'Color depth',
                default: '8',
                value: ''
              },
              {
                name: 'width',
                type: 'number',
                validation: 'between:1,65535',
                required: false,
                description: 'Width',
                default: '1920',
                value: '1920'
              },
              {
                name: 'height',
                type: 'number',
                validation: 'between:1,65535',
                required: false,
                description: 'Width',
                default: '1080',
                value: '1080'
              }
            ]
          },
          {
            group: 'Clipboard',
            parameters: [
              {
                name: 'clipboard-encoding',
                type: 'text',
                validation: 'max:20',
                required: false,
                description: 'Clipboard encoding',
                default: 'CP437',
                value: ''
              },
              {
                name: 'disable-copying',
                type: 'boolean',
                required: false,
                description: 'Disable copying',
                default: false,
                value: ''
              },
              {
                name: 'disable-paste',
                type: 'boolean',
                required: false,
                description: 'Disable pasting',
                default: false,
                value: ''
              }
            ]
          },
          {
            group: 'Screen Recording',
            parameters: [
              {
                name: 'recording-path',
                type: 'text',
                validation: 'max:255',
                required: false,
                description: 'Recording path',
                default: 'C:\\Temp\\',
                value: ''
              },
              {
                name: 'recording-name',
                type: 'text',
                validation: 'max:255',
                required: false,
                description: 'Recording name',
                default: 'recording',
                value: ''
              },
              {
                name: 'recording-exclude-output',
                type: 'boolean',
                required: false,
                description: 'Exclude output',
                default: false,
                value: ''
              },
              {
                name: 'recording-exclude-mouse',
                type: 'boolean',
                required: false,
                description: 'Exclude mouse',
                default: false,
                value: ''
              }
            ]
          },
          {
            group: 'SFTP',
            parameters: [
              {
                name: 'sftp-hostname',
                type: 'text',
                validation: 'ip',
                required: false,
                description: 'SFTP hostname',
                default: 'localhost',
                value: ''
              },
              {
                name: 'sftp-host-key',
                type: 'text',
                validation: 'max:255',
                required: false,
                description: 'SFTP host key',
                default: 'ssh-rsa',
                value: ''
              },
              {
                name: 'sftp-username',
                type: 'text',
                validation: 'alpha_dash',
                required: false,
                description: 'SFTP username',
                default: 'admin',
                value: ''
              },
              {
                name: 'sftp-password',
                type: 'password',
                validation: 'max:60',
                required: false,
                description: 'SFTP password',
                default: 'admin',
                value: ''
              },
              {
                name: 'sftp-private-key',
                type: 'text',
                validation: 'max:255',
                required: false,
                description: 'SFTP private key',
                default: '',
                value: ''
              },
              {
                name: 'sftp-passphrase',
                type: 'text',
                validation: 'max:60',
                required: false,
                description: 'SFTP passphrase',
                default: '',
                value: ''
              },
              {
                name: 'sftp-root-directory',
                type: 'text',
                validation: 'max:255',
                required: false,
                description: 'SFTP root directory',
                default: '/',
                value: ''
              },
              {
                name: 'sftp-directory',
                type: 'text',
                validation: 'max:255',
                required: false,
                description: 'SFTP directory',
                default: '',
                value: ''
              }
            ]
          },
          {
            group: 'Audio',
            parameters: [
              {
                name: 'disable-audio',
                type: 'boolean',
                required: false,
                description: 'Disable audio',
                default: false,
                value: ''
              }
            ]
          },
          {
            group: 'Custom',
            parameters: [
              {
                name: 'custom-parameter',
                type: 'text',
                validation: 'max:255',
                required: false,
                description: 'Custom parameter',
                default: '',
                value: ''
              }
            ]
          }
        ]
        return rdpParameters
      }
      if (type === 'vnc') {
        const vncParameters = [
          {
            group: 'Port',
            parameters: [
              {
                name: 'port',
                type: 'number',
                required: false,
                description: 'Port of the server',
                default: '5900'
              }
            ]
          },
          {
            group: 'Authentication',
            parameters: [
              {
                name: 'username',
                type: 'text',
                required: false,
                description: 'Username of the server',
                default: 'admin',
                value: ''
              },
              {
                name: 'password',
                type: 'password',
                required: false,
                description: 'Password of the server',
                default: 'admin',
                value: ''
              }
            ]
          },
          {
            group: 'Display',
            parameters: [
              {
                name: 'read-only',
                type: 'boolean',
                required: false,
                description: 'Read only mode',
                default: 'false',
                value: ''
              },
              {
                name: 'swap-red-blue',
                type: 'boolean',
                required: false,
                description: 'Swap red and blue',
                default: 'false',
                value: ''
              },
              {
                name: 'cursor',
                type: 'boolean',
                required: false,
                description: 'Show mouse cursor',
                default: 'true',
                value: ''
              },
              {
                name: 'color-depth',
                type: 'number',
                required: false,
                description: 'Color depth',
                default: '8',
                value: ''
              }
            ]
          },
          {
            group: 'Clipboard',
            parameters: [
              {
                name: 'clipboard-encoding',
                type: 'text',
                required: false,
                description: 'Clipboard encoding',
                default: 'CP437',
                value: ''
              },
              {
                name: 'disable-copying',
                type: 'boolean',
                required: false,
                description: 'Disable copying',
                default: false,
                value: ''
              },
              {
                name: 'disable-paste',
                type: 'boolean',
                required: false,
                description: 'Disable pasting',
                default: false,
                value: ''
              }
            ]
          },
          {
            group: 'Screen Recording',
            parameters: [
              {
                name: 'recording-path',
                type: 'text',
                required: false,
                description: 'Recording path',
                default: 'C:\\Temp\\',
                value: ''
              },
              {
                name: 'recording-name',
                type: 'text',
                required: false,
                description: 'Recording name',
                default: 'recording',
                value: ''
              },
              {
                name: 'recording-exclude-output',
                type: 'boolean',
                required: false,
                description: 'Exclude output',
                default: false,
                value: ''
              },
              {
                name: 'recording-exclude-mouse',
                type: 'boolean',
                required: false,
                description: 'Exclude mouse',
                default: false,
                value: ''
              }
            ]
          },
          {
            group: 'SFTP',
            parameters: [
              {
                name: 'sftp-hostname',
                type: 'text',
                required: false,
                description: 'SFTP hostname',
                default: 'localhost',
                value: ''
              },
              {
                name: 'sftp-host-key',
                type: 'text',
                required: false,
                description: 'SFTP host key',
                default: 'ssh-rsa',
                value: ''
              },
              {
                name: 'sftp-username',
                type: 'text',
                required: false,
                description: 'SFTP username',
                default: 'admin'
              },
              {
                name: 'sftp-password',
                type: 'text',
                required: false,
                description: 'SFTP password',
                default: 'admin'
              },
              {
                name: 'sftp-private-key',
                type: 'text',
                required: false,
                description: 'SFTP private key',
                default: ''
              },
              {
                name: 'sftp-passphrase',
                type: 'text',
                required: false,
                description: 'SFTP passphrase',
                default: ''
              },
              {
                name: 'sftp-root-directory',
                type: 'text',
                required: false,
                description: 'SFTP root directory',
                default: '/'
              },
              {
                name: 'sftp-directory',
                type: 'text',
                required: false,
                description: 'SFTP directory',
                default: ''
              }
            ]
          },
          {
            group: 'Audio',
            parameters: [
              {
                name: 'disable-audio',
                type: 'boolean',
                required: false,
                description: 'Disable audio',
                default: 'false'
              }
            ]
          },
          {
            group: 'Custom',
            parameters: [
              {
                name: 'custom-parameter',
                type: 'text',
                required: false,
                description: 'Custom parameter',
                default: '',
                value: ''
              }
            ]
          }
        ]
        return vncParameters
      }
    }
  },
  computed: {},
  created: function () {},
  async mounted() {
    // Get Site Info
    window.Bus.instance.$on('siteDataLoaded', (data) => {
      this.siteData = data
      this.rows = data.rdps
    })
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
