<template>
  <div class="row">
    <!-- Camera Table -->
    <div class="col-12">
      <div class="vue-form-wizard mb-3 md">
        <div class="d-flex justify-content-between flex-wrap mr-1 ml-1">
          <div class="d-flex align-items-center mb-1 mt-1">
            <feather-icon icon="CameraIcon" size="25" class="ml-1 mr-1" />
            <h3 class="">Cameras</h3>
            <span class="text-nowrap ml-1">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-toggle.collapse-1
                variant="primary"
                @click="showCreationWizard()"
              >
                <feather-icon icon="PlusCircleIcon" size="16" class="mr-1" />
                Add Camera
              </b-button>
            </span>
          </div>
        </div>

        <!-- creation Wizard -->
        <b-collapse id="cam-creator" class="ml-1 mr-1">
          <b-card id="cam-creator">
            <validation-observer ref="cameraCreate" tag="form">
              <b-row ref="row" class="ml-1">
                <b-col md="3">
                  <b-form-group label="Name" label-for="camera-name">
                    <validation-provider
                      rules="noSpecial|required"
                      name="camera name"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        type="text"
                        placeholder="Camera Name"
                        v-model="newCamera.name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="URL" label-for="camera-url">
                    <validation-provider
                      rules="url|required"
                      name="camera url"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        id="camera-url"
                        v-model="newCamera.url"
                        type="text"
                        placeholder="URL"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Image URL" label-for="camera-img">
                    <validation-provider
                      rules="url|required"
                      name="camera img"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        id="camera-img"
                        v-model="newCamera.img"
                        type="text"
                        placeholder="Camera Image URL"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <span class="text-nowrap ml-1">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="primary"
                      @click.prevent="validateCamCreator"
                      class="mt-1"
                    >
                      Submit
                    </b-button>
                  </span>
                </b-col>
              </b-row>
            </validation-observer>
          </b-card>
        </b-collapse>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          styleClass="vgt-table"
          :pagination-options="{
            enabled: true,
            perPage: pageLength
          }"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Action -->
            <span v-if="props.column.field === 'actions'">
              <feather-icon
                icon="TrashIcon"
                size="16"
                class="text-body align-middle mr-1 trash-icon"
                @click="showDeleteModal(props.row)"
              />
              <feather-icon
                icon="EditIcon"
                size="16"
                class="text-body align-middle mr-25 table-icon"
                @click="showEditorInSidebar(props.row)"
              />
            </span>

            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- Pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap mb-1 ml-1"> Show</span>

                <b-form-select
                  v-model="pageLength"
                  :options="['5', '10', '20', '50', '100']"
                  class="mx-1 mb-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-1 mr-1"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </div>

    <!-- Editor Sidebar -->
    <b-sidebar
      id="sidebar-cam-editor"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @hidden="resetEditor"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Update {{ editCameraData.name }}</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Body -->
        <b-form class="m-2">
          <validation-observer ref="simpleRules">
            <b-row>
              <b-col>
                <b-form-group label="Camera Name" label-for="camera-name">
                  <validation-provider
                    rules="required"
                    name="camera name"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="editCameraData.name"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Camera URL" label-for="camera-url">
                  <validation-provider
                    rules="required|url"
                    name="camera url"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="editCameraData.url"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Camera Image URL" label-for="img-url">
                  <validation-provider
                    rules="required|url"
                    name="image url"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="editCameraData.img"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- submit -->
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  @click.prevent="validateSiteEditor"
                  variant="primary"
                  class="mr-1"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-form>
      </template>
    </b-sidebar>

    <!-- Deletion Modal -->
    <b-modal
      id="delete-modal"
      hide-footer
      centered
      @hidden="clearDeleteModal()"
    >
      <template #modal-title> Delete Camera</template>
      <div class="d-block text-center">
        <h3>Are you sure you want to delete: {{ cameraToDelete.name }}?</h3>
      </div>
      <div class="mt-2" align="right">
        <b-button variant="primary" @click="$bvModal.hide('delete-modal')"
          >Cancel</b-button
        >
        <b-button class="ml-1" variant="outline-danger" @click="DeleteCamera()"
          >Delete</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BPagination,
  BFormInput,
  BFormSelect,
  BDropdown,
  BModal,
  BSidebar,
  BCard,
  BCardBody,
  BCardTitle,
  BDropdownItem,
  BCollapse,
  BBadge,
  BFormGroup,
  BButton,
  VBToggle,
  BForm,
  BRow,
  BCol,
  BDropdownGroup,
  BFormCheckbox,
  BFormInvalidFeedback
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

// import Mixins
import { heightTransition } from '@core/mixins/ui/transition'
import prepareQueryParamsMixin from '@/mixins/prepareQueryParamsMixin'
import prepareFetchParamsMixin from '@/mixins/prepareFetchParamsMixin'
import vSelect from 'vue-select'

// import validation for editors
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

// import Services
import { regionGroup } from '@/services/regionGroup.service.js'
import { site } from '@/services/site.service.js'

// Import Table
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

// Import Icons
import FeatherIcon from '../../@core/components/feather-icon/FeatherIcon.vue'

// Declare component
export default {
  name: 'CameraTable',
  prop: ['site'],
  mixins: [prepareQueryParamsMixin, prepareFetchParamsMixin],
  components: {
    ValidationProvider,
    ValidationObserver,
    BCollapse,
    BButton,
    VBToggle,
    BModal,
    BBadge,
    BPagination,
    BForm,
    BFormInput,
    BFormSelect,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
    BSidebar,
    BCard,
    BCardBody,
    BCardTitle,
    BRow,
    BCol,
    BDropdownGroup,
    BFormCheckbox,
    FeatherIcon,
    BFormInvalidFeedback,
    vSelect
  },
  directives: {
    FeatherIcon,
    'b-toggle': VBToggle,
    Ripple
  },
  mixins: [heightTransition],
  data() {
    return {
      cameraToDelete: {
        name: '',
        url: '',
        img: ''
      },
      newCamera: {
        name: '',
        url: '',
        img: ''
      },
      siteData: {
        _id: '',
        name: '',
        siteID: '',
        lsd: '',
        lat: '',
        long: '',
        regionGroupID: '',
        groupName: '',
        cameras: [
          {
            name: '',
            url: '',
            img: '',
            _id: ''
          }
        ],
        rdps: [
          {
            name: '',
            ip: '',
            protocol: '',
            _id: ''
          }
        ],
        quicklinks: [
          {
            name: '',
            url: '',
            id: '',
            class: '',
            shortname: ''
          }
        ]
      },
      pageLength: 5,
      rows: [],
      columns: [
        {
          label: 'Name',
          field: 'name',
          width: '20%'
        },
        {
          label: 'Camera URL',
          field: 'url',
          width: '40%'
        },
        {
          label: 'Camera Img URL',
          field: 'img',
          width: '30%'
        },
        {
          label: 'Actions',
          field: 'actions',
          width: '10%'
        }
      ],
      editCameraData: {
        name: '',
        url: '',
        img: '',
        _id: ''
      }
    }
  },
  methods: {
    ////////////////////////////////////////////////////// Camera Deletion Controls ///////////////////////////////////////////
    DeleteCamera() {
      let updatedSite = JSON.parse(JSON.stringify(this.siteData))
      updatedSite.cameras.splice(
        this.cameraToDelete.originalIndex,
        this.cameraToDelete.originalIndex + 1
      )
      site
        .updateSite(updatedSite)
        .then((res) => {
          // set site data to edited
          window.Bus.instance.$emit('pageSuccess', {
            header: 'Success',
            detail: 'Camera Successfully Deleted'
          })
          this.siteData.cameras.splice(
            this.cameraToDelete.originalIndex,
            this.cameraToDelete.originalIndex + 1
          )
          this.$bvModal.hide('delete-modal')
        })
        .catch((error) => {
          window.Bus.instance.$emit('pageDanger', {
            header: 'Error',
            detail: 'Could not Delete Camera'
          })
        })
    },
    showDeleteModal(camera) {
      this.cameraToDelete = camera
      this.$bvModal.show('delete-modal')
    },
    clearDeleteModal() {
      this.cameraToDelete = {
        name: '',
        url: '',
        img: ''
      }
    },
    ////////////////////////////////////////////////////// Camera Creation Controls ///////////////////////////////////////////
    showCreationWizard() {
      this.$root.$emit('bv::toggle::collapse', 'cam-creator')
    },
    validateCamCreator() {
      this.$refs.cameraCreate.validate().then((success) => {
        if (success) {
          let updatedSite = JSON.parse(JSON.stringify(this.siteData))
          updatedSite.cameras.push(this.newCamera)

          site
            .updateSite(updatedSite)
            .then((res) => {
              this.siteData.cameras.push(this.newCamera)
              this.$root.$emit('bv::toggle::collapse', 'cam-creator')

              this.$refs.cameraCreate.reset()
              this.resetCreator()
              // set site data to edited
              window.Bus.instance.$emit('pageSuccess', {
                header: 'Success',
                detail: 'Camera Successfully Created'
              })
            })
            .catch((error) => {
              window.Bus.instance.$emit('pageDanger', {
                header: 'Error',
                detail: error.message
              })
            })
        }
      })
    },

    resetCreator() {
      this.newCamera = {
        name: '',
        url: '',
        img: ''
      }
    },

    ////////////////////////////////////////////////////// Camera Editor Controls //////////////////////////////////////////////

    resetEditor() {
      // clear out editor object
      this.editCameraData = {
        name: '',
        url: '',
        img: '',
        id: ''
      }
    },
    validateSiteEditor() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          this.siteData.cameras[this.editCameraData.originalIndex].name =
            this.editCameraData.name
          this.siteData.cameras[this.editCameraData.originalIndex].url =
            this.editCameraData.url
          this.siteData.cameras[this.editCameraData.originalIndex].img =
            this.editCameraData.img

          site
            .updateSite(this.siteData)
            .then((res) => {
              // set site data to edited
              window.Bus.instance.$emit('pageSuccess', {
                header: 'Success',
                detail: 'Site Successfully Updated'
              })
              this.$root.$emit('bv::toggle::collapse', 'sidebar-cam-editor')
            })
            .catch((error) => {
              window.Bus.instance.$emit('pageDanger', {
                header: 'Error',
                detail: error.message
              })
            })
        }
      })
    },
    showEditorInSidebar(cam) {
      this.editCameraData = cam
      this.$root.$emit('bv::toggle::collapse', 'sidebar-cam-editor')
    }
  },
  computed: {},
  created: function () {},
  async mounted() {
    // Get Site Info
    window.Bus.instance.$on('siteDataLoaded', (data) => {
      this.siteData = data
      this.rows = data.cameras
    })
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-wizard.scss';
.table-icon {
  color: $primary;
  //height: 16px !important;
  //width: 16px !important;
}
.trash-icon {
  color: $danger !important;
}
.align-text-center {
  text-align: center;
}
.placeholder {
  padding: 1rem;
  max-height: 100%;
  max-width: 100%;
}
/* The Close Button */
.close {
  margin-right: -3px !important;
  margin-top: -3px !important;
}
</style>
