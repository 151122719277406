var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"vue-form-wizard mb-3 md"},[_c('div',{staticClass:"d-flex justify-content-between flex-wrap mr-1 ml-1"},[_c('div',{staticClass:"d-flex align-items-center mb-1 mt-1"},[_c('feather-icon',{staticClass:"ml-1 mr-1",attrs:{"icon":"CameraIcon","size":"25"}}),_c('h3',{},[_vm._v("Cameras")]),_c('span',{staticClass:"text-nowrap ml-1"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-toggle",rawName:"v-b-toggle.collapse-1",modifiers:{"collapse-1":true}}],attrs:{"variant":"primary"},on:{"click":function($event){return _vm.showCreationWizard()}}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"PlusCircleIcon","size":"16"}}),_vm._v(" Add Camera ")],1)],1)],1)]),_c('b-collapse',{staticClass:"ml-1 mr-1",attrs:{"id":"cam-creator"}},[_c('b-card',{attrs:{"id":"cam-creator"}},[_c('validation-observer',{ref:"cameraCreate",attrs:{"tag":"form"}},[_c('b-row',{ref:"row",staticClass:"ml-1"},[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"camera-name"}},[_c('validation-provider',{attrs:{"rules":"noSpecial|required","name":"camera name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":"Camera Name"},model:{value:(_vm.newCamera.name),callback:function ($$v) {_vm.$set(_vm.newCamera, "name", $$v)},expression:"newCamera.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"URL","label-for":"camera-url"}},[_c('validation-provider',{attrs:{"rules":"url|required","name":"camera url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"camera-url","type":"text","placeholder":"URL"},model:{value:(_vm.newCamera.url),callback:function ($$v) {_vm.$set(_vm.newCamera, "url", $$v)},expression:"newCamera.url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Image URL","label-for":"camera-img"}},[_c('validation-provider',{attrs:{"rules":"url|required","name":"camera img"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"camera-img","type":"text","placeholder":"Camera Image URL"},model:{value:(_vm.newCamera.img),callback:function ($$v) {_vm.$set(_vm.newCamera, "img", $$v)},expression:"newCamera.img"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('span',{staticClass:"text-nowrap ml-1"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validateCamCreator.apply(null, arguments)}}},[_vm._v(" Submit ")])],1)])],1)],1)],1)],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"styleClass":"vgt-table","pagination-options":{
          enabled: true,
          perPage: _vm.pageLength
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'actions')?_c('span',[_c('feather-icon',{staticClass:"text-body align-middle mr-1 trash-icon",attrs:{"icon":"TrashIcon","size":"16"},on:{"click":function($event){return _vm.showDeleteModal(props.row)}}}),_c('feather-icon',{staticClass:"text-body align-middle mr-25 table-icon",attrs:{"icon":"EditIcon","size":"16"},on:{"click":function($event){return _vm.showEditorInSidebar(props.row)}}})],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap mb-1 ml-1"},[_vm._v(" Show")]),_c('b-form-select',{staticClass:"mx-1 mb-1",attrs:{"options":['5', '10', '20', '50', '100']},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}})],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-1 mr-1",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])})],1)]),_c('b-sidebar',{attrs:{"id":"sidebar-cam-editor","sidebar-class":"sidebar-lg","bg-variant":"white","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":_vm.resetEditor},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Update "+_vm._s(_vm.editCameraData.name))]),_c('div',[_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1)]),_c('b-form',{staticClass:"m-2"},[_c('validation-observer',{ref:"simpleRules"},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Camera Name","label-for":"camera-name"}},[_c('validation-provider',{attrs:{"rules":"required","name":"camera name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.editCameraData.name),callback:function ($$v) {_vm.$set(_vm.editCameraData, "name", $$v)},expression:"editCameraData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Camera URL","label-for":"camera-url"}},[_c('validation-provider',{attrs:{"rules":"required|url","name":"camera url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.editCameraData.url),callback:function ($$v) {_vm.$set(_vm.editCameraData, "url", $$v)},expression:"editCameraData.url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Camera Image URL","label-for":"img-url"}},[_c('validation-provider',{attrs:{"rules":"required|url","name":"image url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.editCameraData.img),callback:function ($$v) {_vm.$set(_vm.editCameraData, "img", $$v)},expression:"editCameraData.img"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validateSiteEditor.apply(null, arguments)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)]}}])}),_c('b-modal',{attrs:{"id":"delete-modal","hide-footer":"","centered":""},on:{"hidden":function($event){return _vm.clearDeleteModal()}},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" Delete Camera")]},proxy:true}])},[_c('div',{staticClass:"d-block text-center"},[_c('h3',[_vm._v("Are you sure you want to delete: "+_vm._s(_vm.cameraToDelete.name)+"?")])]),_c('div',{staticClass:"mt-2",attrs:{"align":"right"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$bvModal.hide('delete-modal')}}},[_vm._v("Cancel")]),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.DeleteCamera()}}},[_vm._v("Delete")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }