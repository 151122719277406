<template>
  <div class="row">
    <!-- QL Table -->
    <div class="col-12">
      <div class="vue-form-wizard mb-3 md">
        <div class="d-flex justify-content-between flex-wrap mr-1 ml-1">
          <div class="d-flex align-items-center mb-1 mt-1">
            <feather-icon icon="ServerIcon" size="25" class="ml-1 mr-1" />
            <h3 class="">Quick Links</h3>
            <span class="text-nowrap ml-1">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-toggle.collapse-1
                variant="primary"
                @click="showCreationWizard()"
              >
                <feather-icon icon="PlusCircleIcon" size="16" class="mr-1" />
                Add QuickLink
              </b-button>
            </span>
          </div>
        </div>

        <!-- creation Wizard -->
        <b-collapse id="ql-creator" class="ml-1 mr-1">
          <b-card id="ql-creator">
            <validation-observer ref="qlCreate" tag="form">
              <b-row ref="row" class="ml-1">
                <b-col md="3">
                  <b-form-group label="Quick Link Name" label-for="ql-name">
                    <validation-provider
                      rules="noSpecial|required"
                      name="ql name"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        type="text"
                        placeholder="QL Name"
                        v-model="newQL.name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group
                    label="Quick Link Short Name"
                    label-for="ql-shortname"
                  >
                    <validation-provider
                      rules="noSpecial|required|min:1|max:2"
                      name="QuickLink Shortname"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        v-model="newQL.shortname"
                        id="QL-shortname"
                        placeholder="Max 2 characters"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group label="URL" label-for="ql-url">
                    <validation-provider
                      rules="url|required"
                      name="ql url"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        id="ql-url"
                        v-model="newQL.url"
                        type="text"
                        placeholder="URL"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="10">
                  <b-form-group label="Quick Link Color" label-for="ql-color">
                    <validation-provider
                      name="Quick Link Color"
                      v-slot="{ errors }"
                    >
                      <div class="d-flex justify-content-start flex-wrap">
                        <div
                          @click="selectColor('primary')"
                          :id="'primary'"
                          class="text-center bg-primary colors-container rounded text-white width-50 height-50 d-flex align-items-center justify-content-center mr-1 ml-50 my-1 bg-lighten-1"
                        ></div>
                        <div
                          @click="selectColor('secondary')"
                          :id="'secondary'"
                          class="text-center bg-secondary colors-container rounded text-white width-50 height-50 d-flex align-items-center justify-content-center mr-1 ml-50 my-1"
                        ></div>
                        <div
                          @click="selectColor('success')"
                          :id="'success'"
                          class="text-center bg-success colors-container rounded text-white width-50 height-50 d-flex align-items-center justify-content-center mr-1 ml-50 my-1"
                        ></div>
                        <div
                          @click="selectColor('warning')"
                          :id="'warning'"
                          class="text-center bg-warning colors-container rounded text-white width-50 height-50 d-flex align-items-center justify-content-center mr-1 ml-50 my-1"
                        ></div>
                        <div
                          @click="selectColor('danger')"
                          :id="'danger'"
                          class="text-center bg-danger colors-container rounded text-white width-50 height-50 d-flex align-items-center justify-content-center mr-1 ml-50 my-1"
                        ></div>
                        <div
                          @click="selectColor('info')"
                          :id="'info'"
                          class="text-center bg-info colors-container rounded text-white width-50 height-50 d-flex align-items-center justify-content-center my-1 ml-50"
                        ></div>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <span class="text-nowrap ml-1">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="primary"
                      @click.prevent="validateQLCreator()"
                      class="mt-1"
                    >
                      Submit
                    </b-button>
                  </span>
                </b-col>
              </b-row>
            </validation-observer>
          </b-card>
        </b-collapse>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          styleClass="vgt-table"
          :pagination-options="{
            enabled: true,
            perPage: pageLength
          }"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Action -->
            <span v-if="props.column.field === 'actions'">
              <feather-icon
                icon="TrashIcon"
                size="16"
                class="text-body align-middle mr-1 trash-icon"
                @click="showDeleteModal(props.row)"
              />
              <feather-icon
                icon="EditIcon"
                size="16"
                class="text-body align-middle mr-25 table-icon"
                @click="showEditorInSidebar(props.row)"
              />
            </span>

            <!-- Column: class -->
            <span v-else-if="props.column.field === 'shortname'">
              <a
                :class="'b-avatar badge-' + props.row.class + ' rounded-circle'"
                style="width: 35px; height: 35px"
                ><span class="b-avatar-text" style="font-size: calc(12.8px)">
                  <span>{{ props.formattedRow[props.column.field] }}</span>
                </span>
              </a>
            </span>

            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- Pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap mb-1 ml-1"> Show</span>

                <b-form-select
                  v-model="pageLength"
                  :options="['5', '10', '20', '50', '100']"
                  class="mx-1 mb-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-1 mr-1"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </div>

    <!-- Editor Sidebar -->
    <b-sidebar
      id="sidebar-ql-editor"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @hidden="resetEditor"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Update {{ editQLData.name }}</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Body -->
        <b-form class="m-2">
          <validation-observer ref="simpleRules">
            <b-row>
              <b-col>
                <b-form-group label="Quick Link Name" label-for="ql-name">
                  <validation-provider
                    rules="required"
                    name="ql name"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="editQLData.name"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Quick Link URL" label-for="ql-url">
                  <validation-provider
                    rules="required|url"
                    name="ql url"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="editQLData.url"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Quick Link Short Name"
                  label-for="ql-shortname"
                >
                  <validation-provider
                    rules="noSpecial|required|min:1|max:2"
                    name="ql shortname"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="editQLData.shortname"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group label="Quick Link Color" label-for="ql-color">
                  <validation-provider
                    name="Quick Link Color"
                    v-slot="{ errors }"
                  >
                    <div class="d-flex justify-content-start flex-wrap">
                      <div
                        @click="selectColorEditor('primary')"
                        :id="'primary-edit'"
                        class="text-center bg-primary colors-container rounded text-white width-50 height-50 d-flex align-items-center justify-content-center mr-1 ml-50 my-1"
                      ></div>
                      <div
                        @click="selectColorEditor('secondary')"
                        :id="'secondary-edit'"
                        class="text-center bg-secondary colors-container rounded text-white width-50 height-50 d-flex align-items-center justify-content-center mr-1 ml-50 my-1"
                      ></div>
                      <div
                        @click="selectColorEditor('success')"
                        :id="'success-edit'"
                        class="text-center bg-success colors-container rounded text-white width-50 height-50 d-flex align-items-center justify-content-center mr-1 ml-50 my-1"
                      ></div>
                      <div
                        @click="selectColorEditor('warning')"
                        :id="'warning-edit'"
                        class="text-center bg-warning colors-container rounded text-white width-50 height-50 d-flex align-items-center justify-content-center mr-1 ml-50 my-1"
                      ></div>
                      <div
                        @click="selectColorEditor('danger')"
                        :id="'danger-edit'"
                        class="text-center bg-danger colors-container rounded text-white width-50 height-50 d-flex align-items-center justify-content-center mr-1 ml-50 my-1"
                      ></div>
                      <div
                        @click="selectColorEditor('info')"
                        :id="'info-edit'"
                        class="text-center bg-info colors-container rounded text-white width-50 height-50 d-flex align-items-center justify-content-center my-1 ml-50"
                      ></div>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- submit -->
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  @click.prevent="validateQLEditor()"
                  variant="primary"
                  class="mr-1"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-form>
      </template>
    </b-sidebar>

    <!-- Deletion Modal -->
    <b-modal
      id="delete-ql-modal"
      hide-footer
      centered
      @hidden="clearDeleteModal()"
    >
      <template #modal-title> Delete QuickLink</template>
      <div class="d-block text-center">
        <h3>Are you sure you want to delete: {{ qlToDelete.name }}?</h3>
      </div>
      <div class="mt-2" align="right">
        <b-button variant="primary" @click="$bvModal.hide('delete-ql-modal')"
          >Cancel</b-button
        >
        <b-button class="ml-1" variant="outline-danger" @click="DeleteQL()"
          >Delete</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BPagination,
  BFormInput,
  BFormSelect,
  BDropdown,
  BModal,
  BSidebar,
  BCard,
  BCardBody,
  BCardTitle,
  BDropdownItem,
  BCollapse,
  BBadge,
  BFormGroup,
  BButton,
  VBToggle,
  BForm,
  BRow,
  BCol,
  BDropdownGroup,
  BFormCheckbox,
  BFormInvalidFeedback
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

// import Mixins
import { heightTransition } from '@core/mixins/ui/transition'
import prepareQueryParamsMixin from '@/mixins/prepareQueryParamsMixin'
import prepareFetchParamsMixin from '@/mixins/prepareFetchParamsMixin'
import vSelect from 'vue-select'

// import validation for editors
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

// import Services
import { regionGroup } from '@/services/regionGroup.service.js'
import { site } from '@/services/site.service.js'

// Import Table
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

// Import Icons
import FeatherIcon from '../../@core/components/feather-icon/FeatherIcon.vue'

// Declare component
export default {
  name: 'QLTable',
  prop: ['site'],
  mixins: [prepareQueryParamsMixin, prepareFetchParamsMixin],
  components: {
    ValidationProvider,
    ValidationObserver,
    BCollapse,
    BButton,
    VBToggle,
    BModal,
    BBadge,
    BPagination,
    BForm,
    BFormInput,
    BFormSelect,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
    BSidebar,
    BCard,
    BCardBody,
    BCardTitle,
    BRow,
    BCol,
    BDropdownGroup,
    BFormCheckbox,
    FeatherIcon,
    BFormInvalidFeedback,
    vSelect
  },
  directives: {
    FeatherIcon,
    'b-toggle': VBToggle,
    Ripple
  },
  mixins: [heightTransition],
  data() {
    return {
      qlToDelete: {
        name: '',
        url: '',
        shortname: '',
        class: 'primary',
        _id: ''
      },
      newQL: {
        name: '',
        url: '',
        shortname: '',
        class: 'primary'
      },
      siteData: {
        _id: '',
        name: '',
        siteID: '',
        lsd: '',
        lat: '',
        long: '',
        regionGroupID: '',
        groupName: '',
        cameras: [
          {
            name: '',
            url: '',
            img: '',
            _id: ''
          }
        ],
        rdps: [
          {
            name: '',
            ip: '',
            protocol: '',
            _id: ''
          }
        ],
        quicklinks: [
          {
            name: '',
            url: '',
            id: '',
            shortname: '',
            class: ''
          }
        ]
      },
      pageLength: 5,
      rows: [],
      columns: [
        {
          label: 'Name',
          field: 'name',
          width: '20%'
        },
        {
          label: 'QL URL',
          field: 'url',
          width: '60%'
        },
        {
          label: 'Preview',
          field: 'shortname',
          width: '10%'
        },
        {
          label: 'Actions',
          field: 'actions',
          width: '10%'
        }
      ],
      editQLData: {
        name: '',
        url: '',
        shortname: '',
        class: 'primary',
        _id: ''
      }
    }
  },
  methods: {
    ////////////////////////////////////////////////////// QL Deletion Controls ///////////////////////////////////////////
    DeleteQL() {
      let updatedSite = JSON.parse(JSON.stringify(this.siteData))
      updatedSite.quicklinks.splice(
        this.qlToDelete.originalIndex,
        this.qlToDelete.originalIndex + 1
      )
      site
        .updateSite(updatedSite)
        .then((res) => {
          // set site data to edited
          window.Bus.instance.$emit('pageSuccess', {
            header: 'Success',
            detail: 'QuickLink Successfully Deleted'
          })
          this.siteData.quicklinks.splice(
            this.qlToDelete.originalIndex,
            this.qlToDelete.originalIndex + 1
          )
          this.$bvModal.hide('delete-ql-modal')
        })
        .catch((error) => {
          window.Bus.instance.$emit('pageDanger', {
            header: 'Error',
            detail: 'Could not Delete QuickLink'
          })
        })
    },
    showDeleteModal(ql) {
      this.qlToDelete = ql
      this.$bvModal.show('delete-ql-modal')
    },
    clearDeleteModal() {
      this.qlToDelete = {
        name: '',
        url: '',
        shortname: '',
        class: 'primary'
      }
    },
    ////////////////////////////////////////////////////// QL Creation Controls ///////////////////////////////////////////
    selectColor(color) {
      // clear currently selected styles
      document.getElementById(this.newQL.class).classList.remove('bg-lighten-1')
      // select new color
      this.newQL.class = color
      document.getElementById(color).classList.add('bg-lighten-1')
    },

    showCreationWizard() {
      this.$root.$emit('bv::toggle::collapse', 'ql-creator')
    },
    validateQLCreator() {
      this.$refs.qlCreate.validate().then((success) => {
        if (success) {
          let updatedSite = JSON.parse(JSON.stringify(this.siteData))
          updatedSite.quicklinks.push(this.newQL)

          site
            .updateSite(updatedSite)
            .then((res) => {
              // set site data to edited
              this.siteData.quicklinks.push(this.newQL)

              this.$root.$emit('bv::toggle::collapse', 'ql-creator')
              this.$refs.qlCreate.reset()
              this.resetCreator()

              window.Bus.instance.$emit('pageSuccess', {
                header: 'Success',
                detail: 'QuickLink Successfully Created'
              })
            })
            .catch((error) => {
              window.Bus.instance.$emit('pageDanger', {
                header: 'Error',
                detail: error.message
              })
            })
        }
      })
    },

    resetCreator() {
      // clear currently selected styles
      document.getElementById(this.newQL.class).classList.remove('bg-lighten-1')

      // reset selection
      document.getElementById('primary').classList.add('bg-lighten-1')

      this.newQL = {
        name: '',
        url: '',
        shortname: '',
        class: 'primary'
      }
    },

    ////////////////////////////////////////////////////// QL Editor Controls //////////////////////////////////////////////
    selectColorEditor(color) {
      // clear currently selected styles
      document
        .getElementById(this.editQLData.class + '-edit')
        .classList.remove('bg-lighten-1')
      // select new color
      this.editQLData.class = color
      document.getElementById(color + '-edit').classList.add('bg-lighten-1')
    },
    resetEditor() {
      document
        .getElementById(this.editQLData.class + '-edit')
        .classList.remove('bg-lighten-1')
      // clear out editor object
      this.editQLData = {
        name: '',
        url: '',
        shortname: '',
        class: 'primary',
        _id: ''
      }
    },
    validateQLEditor() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          this.siteData.quicklinks[this.editQLData.originalIndex].name =
            this.editQLData.name
          this.siteData.quicklinks[this.editQLData.originalIndex].url =
            this.editQLData.url
          this.siteData.quicklinks[this.editQLData.originalIndex].shortname =
            this.editQLData.shortname
          this.siteData.quicklinks[this.editQLData.originalIndex].class =
            this.editQLData.class

          site
            .updateSite(this.siteData)
            .then((res) => {
              // set site data to edited
              window.Bus.instance.$emit('pageSuccess', {
                header: 'Success',
                detail: 'QuickLink Successfully Updated'
              })
              this.$root.$emit('bv::toggle::collapse', 'sidebar-ql-editor')
            })
            .catch((error) => {
              window.Bus.instance.$emit('pageDanger', {
                header: 'Error',
                detail: error.message
              })
            })
        }
      })
    },
    showEditorInSidebar(ql) {
      this.editQLData = JSON.parse(JSON.stringify(ql))
      document
        .getElementById(this.editQLData.class + '-edit')
        .classList.add('bg-lighten-1')
      this.$root.$emit('bv::toggle::collapse', 'sidebar-ql-editor')
    }
  },
  computed: {},
  created: function () {},
  async mounted() {
    // Get Site Info
    window.Bus.instance.$on('siteDataLoaded', (data) => {
      this.siteData = data
      this.rows = data.quicklinks
    })
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-wizard.scss';
.table-icon {
  color: $primary;
  //height: 16px !important;
  //width: 16px !important;
}
.trash-icon {
  color: $danger !important;
}
.align-text-center {
  text-align: center;
}
.placeholder {
  padding: 1rem;
  max-height: 100%;
  max-width: 100%;
}
/* The Close Button */
.close {
  margin-right: -3px !important;
  margin-top: -3px !important;
}
.bg-lighten-1 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}
</style>
