var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"vue-form-wizard mb-3 md"},[_c('div',{staticClass:"d-flex justify-content-between flex-wrap mr-1 ml-1"},[_c('div',{staticClass:"d-flex align-items-center mb-1 mt-1"},[_c('feather-icon',{staticClass:"ml-1 mr-1",attrs:{"icon":"ServerIcon","size":"25"}}),_c('h3',{},[_vm._v("Remote Desktops")]),_c('span',{staticClass:"text-nowrap ml-1"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-toggle",rawName:"v-b-toggle.collapse-1",modifiers:{"collapse-1":true}}],attrs:{"variant":"primary"},on:{"click":function($event){return _vm.showCreationWizard()}}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"PlusCircleIcon","size":"16"}}),_vm._v(" Add Desktop ")],1)],1)],1)]),_c('b-collapse',{staticClass:"ml-1 mr-1",attrs:{"id":"rdp-creator"}},[_c('b-card',{attrs:{"id":"rdp-creator"}},[_c('validation-observer',{ref:"rdpCreate",attrs:{"tag":"form"}},[_c('b-row',{ref:"row",staticClass:"ml-1"},[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"rdp-name"}},[_c('validation-provider',{attrs:{"rules":"noSpecial|required","name":"rdp name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":"RDP Name"},model:{value:(_vm.newRDP.name),callback:function ($$v) {_vm.$set(_vm.newRDP, "name", $$v)},expression:"newRDP.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"IP","label-for":"rdp-ip"}},[_c('validation-provider',{attrs:{"rules":"required","name":"rdp ip"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"rdp-ip","type":"text","placeholder":"IP Address"},model:{value:(_vm.newRDP.ip),callback:function ($$v) {_vm.$set(_vm.newRDP, "ip", $$v)},expression:"newRDP.ip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Type","label-for":"rdp-protocol"}},[_c('validation-provider',{attrs:{"rules":"required","name":"rdp protocol"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"border rounded",attrs:{"placeholder":"Select Type","id":"remote-protocol","reduce":function (option) { return option.protocol; },"options":_vm.createConnection,"state":errors.length > 0 ? false : null},on:{"option:selected":function($event){return _vm.changedProtocol(_vm.newRDP.protocol)}},model:{value:(_vm.newRDP.protocol),callback:function ($$v) {_vm.$set(_vm.newRDP, "protocol", $$v)},expression:"newRDP.protocol"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Additional Attributes","label-for":"rdp-ip"}},[_c('validation-provider',{attrs:{"rules":"required","name":"rdp protocol"}},[_c('v-select',{staticClass:"border rounded",attrs:{"multiple":"","placeholder":_vm.chooseAttribute.name,"label":"group","close-on-select":false,"disabled":!_vm.newRDP.protocol,"deselectFromDropdown":true,"clearable":true,"options":_vm.rdpParameterList(_vm.newRDP.protocol)},model:{value:(_vm.newConnection),callback:function ($$v) {_vm.newConnection=$$v},expression:"newConnection"}})],1)],1)],1)],1),_vm._l((_vm.newConnection),function(parameter,index){return _c('div',{key:index},[_c('hr'),_c('b-row',{staticClass:"ml-1 mr-1"},[_c('b-col',{attrs:{"md":"12"}},[_c('h3',[_vm._v(_vm._s(parameter.group))])]),_vm._l((parameter.parameters),function(attribute,index){return _c('b-col',{key:index,staticClass:"mb-1",attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":attribute.description}},[_c('validation-provider',{attrs:{"rules":attribute.validation,"name":attribute.description},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(
                          attribute.type === 'text' ||
                          attribute.type === 'password' ||
                          attribute.type === 'number'
                        )?_c('b-form-input',{attrs:{"type":attribute.type,"placeholder":attribute.default},model:{value:(attribute.value),callback:function ($$v) {_vm.$set(attribute, "value", $$v)},expression:"attribute.value"}}):_vm._e(),(attribute.type === 'boolean')?_c('b-form-checkbox',{attrs:{"placeholder":attribute.default},model:{value:(attribute.value),callback:function ($$v) {_vm.$set(attribute, "value", $$v)},expression:"attribute.value"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)})],2)],1)}),_c('b-col',{attrs:{"md":"2"}},[_c('span',{staticClass:"text-nowrap ml-1"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validateRDPCreator()}}},[_vm._v(" Submit ")])],1)])],2)],1)],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"styleClass":"vgt-table","pagination-options":{
          enabled: true,
          perPage: _vm.pageLength
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'actions')?_c('span',[_c('feather-icon',{staticClass:"text-body align-middle mr-1 trash-icon",attrs:{"icon":"TrashIcon","size":"16"},on:{"click":function($event){return _vm.showDeleteModal(props.row)}}}),_c('feather-icon',{staticClass:"text-body align-middle mr-25 table-icon",attrs:{"icon":"EditIcon","size":"16"},on:{"click":function($event){return _vm.showEditorInSidebar(props.row)}}})],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap mb-1 ml-1"},[_vm._v(" Show")]),_c('b-form-select',{staticClass:"mx-1 mb-1",attrs:{"options":['5', '10', '20', '50', '100']},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}})],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-1 mr-1",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])})],1)]),_c('b-sidebar',{attrs:{"id":"sidebar-rdp-editor","sidebar-class":"sidebar-lg","bg-variant":"white","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":_vm.resetEditor},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Update "+_vm._s(_vm.editRDPData.name))]),_c('div',[_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1)]),_c('b-form',{staticClass:"m-2"},[_c('validation-observer',{ref:"simpleRules"},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Connection Name","label-for":"rdp-name"}},[_c('validation-provider',{attrs:{"rules":"required","name":"rdp name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.editRDPData.name),callback:function ($$v) {_vm.$set(_vm.editRDPData, "name", $$v)},expression:"editRDPData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Connection IP","label-for":"rdp-ip"}},[_c('validation-provider',{attrs:{"rules":"required","name":"rdp ip"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.editRDPData.ip),callback:function ($$v) {_vm.$set(_vm.editRDPData, "ip", $$v)},expression:"editRDPData.ip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('h4',[_vm._v("Additional Parameters")]),_c('hr'),_vm._l((_vm.editRDPData.parameters),function(editparameter,index){return _c('div',{key:index},[_c('p',[_vm._v(_vm._s(editparameter.group))]),_vm._l((editparameter.parameters),function(editattribute,index){return _c('b-col',{key:index,staticClass:"mb-1"},[_c('b-form-group',{attrs:{"label":editattribute.description}},[_c('validation-provider',{attrs:{"rules":editattribute.validation,"name":editattribute.description},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [(
                          editattribute.type === 'text' ||
                          editattribute.type === 'password' ||
                          editattribute.type === 'number'
                        )?_c('b-form-input',{attrs:{"type":editattribute.type,"placeholder":editattribute.default},model:{value:(editattribute.value),callback:function ($$v) {_vm.$set(editattribute, "value", $$v)},expression:"editattribute.value"}}):_vm._e(),(editattribute.type === 'boolean')?_c('b-form-checkbox',{attrs:{"placeholder":editattribute.default},model:{value:(editattribute.value),callback:function ($$v) {_vm.$set(editattribute, "value", $$v)},expression:"editattribute.value"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)})],2)})],2)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validateRDPEditor()}}},[_vm._v(" Submit ")])],1)],1)],1)],1)]}}])}),_c('b-modal',{attrs:{"id":"delete-rdp-modal","hide-footer":"","centered":""},on:{"hidden":function($event){return _vm.clearDeleteModal()}},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" Delete Connection")]},proxy:true}])},[_c('div',{staticClass:"d-block text-center"},[_c('h3',[_vm._v("Are you sure you want to delete: "+_vm._s(_vm.rdpToDelete.name)+"?")])]),_c('div',{staticClass:"mt-2",attrs:{"align":"right"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$bvModal.hide('delete-rdp-modal')}}},[_vm._v("Cancel")]),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.DeleteRDP()}}},[_vm._v("Delete")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }