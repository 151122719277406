<template>
  <div>
    <!-- Site Info and Location -->
    <b-row>
      <b-col md="4">
        <b-card>
          <b-card-title
            ><h1>{{ siteData.name }}</h1>
            <p>{{ siteData.groupName }}</p>
          </b-card-title>
          <b-row>
            <b-col md="6">
              <div class="d-flex">
                <feather-icon icon="MapIcon" class="mr-1" />
                <h6>LSD</h6>
              </div>
              {{ siteData.lsd }}
            </b-col>
            <b-col md="6">
              <div class="d-flex">
                <feather-icon icon="ActivityIcon" class="mr-1" />
                <h6>Status</h6>
              </div>
              <b-badge :variant="statusVariant(siteData.status)">
                {{ siteData.status }}
              </b-badge>
            </b-col>
            <b-col md="6">
              <div class="d-flex">
                <feather-icon icon="CheckIcon" class="mr-1" />
                <h6>SiteID</h6>
              </div>
              {{ siteData.siteID }}
            </b-col>
          </b-row>
          <b-row>
            <span class="align-items-right text-nowrap ml-1 mt-1">
              <b-button
                @click="showEditorInSidebar()"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                b-toggle.collapse-1
                variant="primary"
              >
                Edit
              </b-button>
            </span>
            <span class="align-items-right text-nowrap ml-1 mt-1">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                b-toggle.collapse-1
                variant="outline-danger"
                @click="showDeleteModal(siteData.siteID, siteData.name)"
              >
                Delete
              </b-button>
            </span>
          </b-row>
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <b-card-text>
            <b-row>
              <b-col cols="12">
                <div
                  class="editBtn"
                  sm="4"
                  md="2"
                  id="EditMap"
                  v-if="this.mapMarker === false"
                >
                  <b-button variant="primary" @click="mapEdit"> Edit </b-button>
                </div>
                <CreateMap
                  @handlemarkerupdate="updateMarker"
                  :markerControl="true"
                  :draggable="mapMarker"
                  :selectable="false"
                  :sites="siteMarker()"
                ></CreateMap>
              </b-col>
            </b-row>
          </b-card-text>
          <b-card-body v-if="this.mapMarker === true">
            <b-form>
              <validation-observer ref="geoRules">
                <b-row>
                  <b-col sm="6" md="6" lg="4" id="LatitudeForm">
                    <b-form-group label="Latitude" label-for="latInput">
                      <validation-provider
                        rules="required|validLat"
                        name="Latitude"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          id="latInput"
                          type="number"
                          :state="errors.length > 0 ? false : null"
                          v-model="
                            siteData.geoJSON[0].features[0].geometry[0]
                              .coordinates[1]
                          "
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6" md="6" lg="4" id="LongitudeForm">
                    <b-form-group label="Longitude" label-for="longInput">
                      <validation-provider
                        rules="required|validLng"
                        name="Longitude"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          id="longInput"
                          type="number"
                          :state="errors.length > 0 ? false : null"
                          v-model="
                            siteData.geoJSON[0].features[0].geometry[0]
                              .coordinates[0]
                          "
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col sm="12" md="12" lg="4" class="mt-2">
                    <div class="row">
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        b-toggle.collapse-1
                        @click="mapEdit"
                        class="ml-1 mr-1 mb-1"
                        variant="outline-danger"
                      >
                        Cancel
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        b-toggle.collapse-1
                        class="ml-1 mr-1 mb-1"
                        variant="outline-success"
                        @click="saveSiteLocation"
                      >
                        Save
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </validation-observer>
            </b-form>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <!-- Site Editor Sidebar -->
    <b-sidebar
      id="sidebar-editor"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @hidden="resetEditor"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Update {{ siteData.name }}</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Body -->
        <b-form class="m-2">
          <validation-observer ref="simpleRules">
            <b-row>
              <b-col>
                <b-form-group label="Site ID" label-for="siteID">
                  <validation-provider
                    rules="required|validSiteID"
                    name="siteID"
                  >
                    <b-form-input disabled v-model="editSiteData.siteID" />
                  </validation-provider>
                </b-form-group>

                <div class="row mb-1">
                  <div class="col-10">Published</div>
                  <b-form-checkbox
                    checked="true"
                    name="check-button"
                    :options="statusOptions"
                    v-model="editSiteData.status"
                    switch
                    inline
                  >
                  </b-form-checkbox>
                </div>

                <validation-provider
                  #default="{ errors }"
                  name="Region Group"
                  rules="required"
                >
                  <b-form-group label="Region Group" label-for="regiongroup">
                    <v-select
                      placeholder="Choose One"
                      :options="allRegionGroups"
                      label="name"
                      :state="errors.length > 0 ? false : null"
                      v-model="editSiteData.regionGroup"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <b-form-group label="Site Name" label-for="site-name">
                  <validation-provider
                    rules="required"
                    name="site name"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="editSiteData.name"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="LSD" label-for="lsd">
                  <validation-provider
                    rules="required|min:10|max:14"
                    name="lsd"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="editSiteData.lsd"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- submit -->
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  @click.prevent="validateSiteEditor"
                  variant="primary"
                  class="mr-1"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-form>
      </template>
    </b-sidebar>

    <!-- Contractor Table -->
    <contractor-table />
    <!-- Camera Table -->
    <cameras-table v-bind:site="siteData" />

    <!-- RDP Table -->
    <rdp-table />

    <!-- QuickLink Table -->
    <quick-links-table />

    <!-- Deletion Modal -->
    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      title="Delete Site"
      ok-title="Delete"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <h6>Warning</h6>
      <p>Please enter in the name of the Site to confirm deletion. <br /></p>
      <span
        >Name:
        <h6>{{ siteToDelete.name }}</h6></span
      >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="Name"
          label-for="name-input"
          invalid-feedback="Name Must Match Region Group Name"
          :state="nameState"
        >
          <b-form-input
            id="name-input"
            v-model="name"
            :state="nameState"
            required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { BToggle } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

// import Mixins
import { heightTransition } from '@core/mixins/ui/transition'
import prepareQueryParamsMixin from '@/mixins/prepareQueryParamsMixin'
import prepareFetchParamsMixin from '@/mixins/prepareFetchParamsMixin'

// import validation for editors
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import { required, validLng, validLat } from '@validations'

// import Services
import { regionGroup } from '@/services/regionGroup.service.js'
import { site } from '@/services/site.service.js'

// Import Table
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

// Import Map
import CreateMap from '@/components/maps/MapComponent.vue'

// Import vSelect
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// Import Icons
import FeatherIcon from '../../@core/components/feather-icon/FeatherIcon.vue'

import CamerasTable from '../..//components/siteEditor/CamerasTable.vue'
import RdpTable from '../../components/siteEditor/RdpTable.vue'
import QuickLinksTable from '../../components/siteEditor/QuickLinksTable.vue'
import ContractorTable from '../../components/siteEditor/ContractorTable.vue'
import {
  BCardText,
  BCardTitle,
  BBadge,
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
  BButton,
  BSidebar
} from 'bootstrap-vue'
// Declare component
export default {
  name: 'SiteEditor',
  prop: ['id'],
  mixins: [prepareQueryParamsMixin, prepareFetchParamsMixin],
  components: {
    ValidationProvider,
    ValidationObserver,
    CamerasTable,
    CreateMap,
    FeatherIcon,
    QuickLinksTable,
    RdpTable,
    QuickLinksTable,
    ContractorTable,
    BCardText,
    BCardTitle,
    BBadge,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BSidebar
  },
  directives: {
    FeatherIcon,
    Ripple
  },
  mixins: [heightTransition],
  data() {
    return {
      mapMarker: false,

      siteData: {
        _id: '',
        name: '',
        siteID: '',
        lsd: '',
        geoJSON: [
          {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: [
                  {
                    coordinates: []
                  }
                ]
              }
            ]
          }
        ],
        status: 'Unpublished',
        regionGroupID: '',
        groupName: '',
        cameras: [
          {
            name: '',
            url: '',
            img: '',
            id: ''
          }
        ],
        rdps: [
          {
            name: '',
            url: '',
            ip: '',
            protocol: '',
            id: ''
          }
        ],
        quicklinks: [
          {
            name: '',
            url: '',
            id: '',
            class: ''
          }
        ]
      },
      editSiteData: {
        name: '',
        siteID: '',
        lsd: '',
        geoJSON: [],
        regionGroupID: '',
        groupName: '',
        status: true
      },
      allRegionGroups: [
        {
          name: 'Loading',
          _id: '1'
        }
      ],
      siteToDelete: {
        name: '',
        id: ''
      },
      name: '',
      nameState: '',
      statusOptions: {},
      tempCoords: []
    }
  },
  methods: {
    // //////////////////////////////////// Deletion Modal Controls ////////////////////////////////////////////
    checkFormValidity() {
      const valid = this.name === this.siteToDelete.name ? true : false
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      // Push the name to submitted names
      this.deleteSite(this.siteToDelete.id)
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing')
      })
    },
    showDeleteModal(id, name) {
      this.siteToDelete.name = name
      this.siteToDelete.id = id
      this.$bvModal.show('modal-prevent-closing')
    },
    async deleteSite(id) {
      site
        .deleteSite({ siteID: id })
        .then((res) => {
          window.Bus.instance.$emit('pageSuccess', {
            header: 'SUCCESS',
            detail: res.message
          })
          this.$router.push('/sitemanager')
        })
        .catch((error) => {
          window.Bus.instance.$emit('pageDanger', {
            header: 'ERROR',
            detail: error.message
          })
        })
    },

    ////////////////////////////////////////////////////// Site Editor Controls //////////////////////////////////////////////
    // Fetch Data For Region Groups and Return Response To Groups
    async getAllRegionGroups() {
      try {
        let data = await regionGroup.getAllRegionGroups()
        this.allRegionGroups = data.data
      } catch (error) {
        window.Bus.instance.$emit('pageDanger', {
          header: 'Error',
          detail: error.message
        })
      }
    },
    resetEditor() {
      // clear out editor object
      this.editSiteData = {
        name: '',
        siteID: '',
        lsd: '',
        lat: '',
        long: '',
        regionGroupID: '',
        groupName: '',
        status: true
      }
    },
    validateSiteEditor() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          let updatedSite = this.siteData
          updatedSite.name = this.editSiteData.name
          updatedSite.lsd = this.editSiteData.lsd
          updatedSite.siteID = this.editSiteData.siteID
          updatedSite.status = this.editSiteData.status
            ? 'Published'
            : 'Unpublished'
          updatedSite.groupName = this.editSiteData.regionGroup.name
          updatedSite.regionGroupID = this.editSiteData.regionGroup._id

          site
            .updateSite(updatedSite)
            .then((res) => {
              // set site data to edited
              window.Bus.instance.$emit('pageSuccess', {
                header: 'Success',
                detail: 'Site Successfully Updated'
              })
              this.$root.$emit('bv::toggle::collapse', 'sidebar-editor')
            })
            .catch((error) => {
              window.Bus.instance.$emit('pageDanger', {
                header: 'Error',
                detail: error.message
              })
            })
        }
      })
    },
    showEditorInSidebar() {
      this.editSiteData = {
        name: this.siteData.name,
        siteID: this.siteData.siteID,
        lsd: this.siteData.lsd,
        lat: this.siteData.lat,
        long: this.siteData.long,
        regionGroupID: this.siteData.regionGroupID,
        groupName: this.siteData.groupName,
        status: this.siteData.status === 'Published' ? true : false,
        regionGroup: {
          name: this.siteData.groupName,
          _id: this.siteData.regionGroupID
        }
      }
      this.$root.$emit('bv::toggle::collapse', 'sidebar-editor')
    },
    getSiteBysiteID(Id) {
      site
        .getSiteBysiteID(Id)
        .then((data) => {
          this.siteData = data.data.data
          window.Bus.instance.$emit('siteDataLoaded', this.siteData)
        })
        .catch((error) => {
          window.Bus.instance.$emit('pageDanger', {
            header: 'ERROR',
            detail: error.message
          })
        })
    },

    //////////////////////////////////////////////////////////////////////////// MAP HANDLES ////////////////////////////////////////////////////////////////////
    saveSiteLocation() {
      this.$refs.geoRules.validate().then((success) => {
        if (success) {
          site
            .updateSiteLocation(this.siteData)
            .then((data) => {
              window.Bus.instance.$emit(
                'flyToCoords',
                this.siteData.geoJSON[0].features[0].geometry[0].coordinates
              )
              this.tempCoords = JSON.parse(
                JSON.stringify(
                  this.siteData.geoJSON[0].features[0].geometry[0].coordinates
                )
              )
              this.mapMarker = false
            })
            .catch((error) => {
              window.Bus.instance.$emit('pageDanger', {
                header: 'ERROR',
                detail: error.message
              })
            })
        }
      })
    },
    siteMarker() {
      let data = [this.siteData]
      return data
    },
    mapEdit() {
      // if this.mapmarker is true then change it to false else change it to true
      if (this.mapMarker) {
        // reset coords if canceled
        this.siteData.geoJSON[0].features[0].geometry[0].coordinates =
          this.tempCoords
        window.Bus.instance.$emit('flyToCoords', this.tempCoords)
        this.mapMarker = false
      } else {
        this.tempCoords = JSON.parse(
          JSON.stringify(
            this.siteData.geoJSON[0].features[0].geometry[0].coordinates
          )
        )
        this.mapMarker = true
      }
    },
    updateMarker(updateMarker) {
      this.siteData.geoJSON[0].features[0].geometry[0].coordinates = [
        updateMarker.lng,
        updateMarker.lat
      ]
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Published: 'light-success',
        Unpublished: 'light-warning'
        /* eslint-enable key-spacing */
      }
      return (status) => statusColor[status]
    }
  },
  created: function () {},
  async mounted() {
    // Get Site Info
    this.getSiteBysiteID(this.$attrs.id)
    // Get Contractor Info

    // Get Region Groups
    this.getAllRegionGroups()
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.editBtn {
  position: absolute;
  margin: 10px;
  z-index: 1;
}
.table-icon {
  color: $primary;
  //height: 16px !important;
  //width: 16px !important;
}
.trash-icon {
  color: $danger !important;
}
.align-text-center {
  text-align: center;
}
.placeholder {
  padding: 1rem;
  max-height: 100%;
  max-width: 100%;
}
.map-container {
  height: 40vh;
  width: 100%;
}
</style>
