<template></template>

<script>
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import { $helpers } from 'v-mapbox'
import Vue from 'vue'

const drawEvents = {
  create: 'create',
  delete: 'delete',
  combine: 'combine',
  uncombine: 'uncombine',
  update: 'update',
  selectionchange: 'selectionchange',
  modechange: 'modechange',
  render: 'render',
  actionable: 'actionable'
}

export default {
  name: 'DrawControl',
  mixins: [$helpers.asControl],
  inject: ['mapbox', 'map'],
  props: {
    displayControlsDefault: {
      type: Boolean,
      default: null
    },
    controls: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      initial: true
    }
  },

  watch: {},

  created() {
    this.control = new MapboxDraw(this.$props)
    this.$_deferredMount()
  },
  methods: {
    $_deferredMount() {
      this.map.addControl(this.control)
      this.$_bindSelfEvents(Object.keys(drawEvents))
      this.initial = false
    },

    $_bindSelfEvents(events) {
      const vm = this
      Object.keys(this.$listeners).forEach((eventName) => {
        if (events.includes(eventName)) {
          this.map.on(
            'draw.' + eventName,
            vm.$_emitControlEvent.bind(vm, eventName)
          )
        }
      })
    },
    $_emitControlEvent(eventName, eventData) {
      return this.$_emitSelfEvent({ type: eventName }, eventData)
    }
  }
}
</script>
